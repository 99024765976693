import React from 'react'
import Seo from '../components/Seo'
import PageNotFound from '../components/404'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404" description="Page not found" />
      <PageNotFound />
    </>
  )
}

export default NotFoundPage
