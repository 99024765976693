import React from 'react'
import { PageNotFoundMain } from './index.style'
import Container from '../Container'
import Spacer from '../Spacer'
import { Heading1, TextBodySmall } from '../TextStyles'
import Button from '../Button'

const PageNotFound = () => {
  return (
    <PageNotFoundMain>
      <Container>
        <Spacer size={[160, 394]} />

        <TextBodySmall>Error 404</TextBodySmall>

        <Spacer size={[16, 40]} />

        <Heading1>Page not found!</Heading1>

        <Spacer size={[24, 60]} />

        <Button type="internalLink" to="/">
          Go to Homepage
        </Button>

        <Spacer size={[120, 394]} />
      </Container>
    </PageNotFoundMain>
  )
}

export default PageNotFound
